const firebaseConfig = {
  apiKey: "AIzaSyACCMiBa9iQ_8SmCNlpIlY0topRjEHHheY",
  authDomain: "deltachat-app.firebaseapp.com",
  projectId: "deltachat-app",
  storageBucket: "deltachat-app.appspot.com",
  messagingSenderId: "330588508445",
  appId: "1:330588508445:web:7852f96e4f122532f5d1f4",
  measurementId: "G-Z7LJR2NQBX",
};

export default firebaseConfig;
